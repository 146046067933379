const $blog_cards = $('.blog-card')
let url_hash = window.location.hash;
console.log(url_hash);

$('.blog-card-filters_buttons p').click(function() {
  let filter = $(this).data('filter');

  if ($(this).hasClass('active')) {
    $('.blog-card-filters_buttons p').removeClass('active');
    $blog_cards.each(function() {
      $(this).removeClass('hide-blog-card');
    });
  } else {
    // Remove hide-blog-card class from all cards
    $blog_cards.removeClass('hide-blog-card');

    // Add hide-blog-card class to cards that do not match the filter
    $blog_cards.each(function() {
      if ($(this).data('category') !== filter) {
        $(this).addClass('hide-blog-card');
      }
    });

    // Remove active class from all filter buttons
    $('.blog-card-filters_buttons p').removeClass('active');

    // Add active class to the clicked filter button
    $(this).addClass('active');
  }

});

if (url_hash) {
  $('.blog-card-filters_buttons p[data-name="' + url_hash + '"]').click();
}

const mobileMenuBreakpoint = 1200;
const $win = $(window);
const $body = $('body');

const $header = $('.site-header-wrap');
const $headerMenu = $('.site-header');
const $headerNav = $('.site-header-wrap .site-header .wp-block-navigation');
const $subMenu = $('.site-header-wrap .wp-block-navigation__submenu-container')

function NavigateMobileSubMenu(level, $subMenu) {
  $subMenu = $subMenu || null;
  if($subMenu) {
    $headerMenu.find('.sub-menu[data-depth=' + level + ']').removeClass('active');
    $subMenu.addClass('active');
  }
  $headerMenu.css('transform', 'translate(' + (-100 * level) + '%)');
  $headerNav.attr('data-currentlevel', level);
}

$subMenu.each((index, element) => {
  const $this = $(element);
  $this.prepend('<div class="back-btn">Back</div>');
});

$(document).on('click', '.back-btn', (e)=> {
  console.log('this is getting clicked')
  const $this = $(e.currentTarget)
  $this.parent().removeClass('active')
})

$('.site-header .has-child').each(function (){
  const $self = $(this);
  const $link = $self.find('> a');
  const $subMenu = $self.find('> .wp-block-navigation__submenu-container');
  const $subMenuBack = $subMenu.find('> .sub-menu__back');

  const linkHref = $link.attr('href');

  if(linkHref !== '#' && linkHref !== '' && linkHref !== undefined) {
    const $menuItemDuplicate = $('<li class="menu-item menu-item--duplicate"></li>');
    $link.clone().appendTo($menuItemDuplicate);
    $subMenuBack.after($menuItemDuplicate);
  }

  $link.click(function (event) {
    if($win.width() <= mobileMenuBreakpoint) {
      event.preventDefault();
      NavigateMobileSubMenu($subMenu.attr('data-depth'), $subMenu);
    }
  });
});

$('.mobile-close, .mobile-menu-toggle').click(function () {
  $headerNav.toggleClass('open');
  $(this).toggleClass('is-active');
});

// Add header height as css var.
function setMenuHeightVar() {
  $body.css('--header-height', $header.height() + 'px');
}

// Do this on page load
setMenuHeightVar();

// Do this on resize
window.addEventListener('resize', event => {
  setMenuHeightVar();
});

$('.has-child a').on('click', (e)=> {
  e.stopPropagation();
  const $this = $(e.currentTarget);
  $this.parent().toggleClass('open-sub-menu');
})

$(document).on('click', (e)=> {
  const $target = $(e.currentTarget);
  if(!$target.hasClass('has-child')) {
    $('.has-child').removeClass('open-sub-menu');
  }
})

const $tri_block_container = $('.tri-block-container');

if ($tri_block_container.length > 0) {
  if($(window).width() > 1200) {
    let $tri_blocks = $('.tri-block-section');
    $tri_blocks.on('mouseenter', (e) => {
      const $this = $(e.currentTarget);
      $tri_blocks.removeClass('tri-block-hover');
      $this.addClass('tri-block-hover');
    })
  }
}
